import React from 'react'

function Header() {
  return (
    <div className="signin">
    <h1>Welcome to Ekitchen!</h1>
    <h3>Login</h3>
    <h3>Logout</h3>
    </div>
    
  )
}

export default Header